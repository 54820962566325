<template>
    <div  class="wrap">
        <img  class="aboutUs"
                alt="aboutUs logo"
                src="@/assets/aboutUs.jpg">
    </div>
  </template>
  
  <style lang="less" scoped>
.wrap {
  width: 1200px;
  margin: 0 auto;
  padding: 10px 0 30px 0;
  .aboutUs{
        width: 100%;
  }
}

  </style>
  
  <script>
import { mapMutations } from "vuex";
import { getToken } from "utils/session.js";
  export default {
    name:"About",
    data(){
        return {

        }
    },
  //路由进入时  显示未登陆状态头部
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      vm.changeFlag(true);
    });
  },
  //路由离开时 隐藏未登陆状态头部
  beforeRouteLeave(to, from, next) {
    if (getToken() != null) {
      this.changeFlag(false);
    }
    next();
  },
  methods: {
    ...mapMutations({
      changeFlag: "changeFlag",
    }),
  },
};
</script>
  